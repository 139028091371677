
// 校验表单
let formObj = {
    name: '机构名称',
    addressDetail: "详细地址",
    phone: "联系方式",
    organCode: "统一社会信用代码",
    regionCode: "地址",
    authCode: "验证码", //验证码
    businessLicenseImage: "营业执照副本",
    trainTeacherImage: "培训教师资格证明",
    workType: "职业培训及工种", //工种
    headTeacherVoList: '班主任信息', // 班主任信息
    letterList:'承诺函',
    rightInfoDto:'知识产权信息',
    urlList:'师资备案'
}

export let checkForm = (formData)=>{
    for(let item in formObj){
        if(formData.enterType == 1 && (item == 'workType' || item == 'trainTeacherImage')) {
            continue;
        }
        let hasObj = Object.prototype.hasOwnProperty.call(formData, item) // 检查属性是否存在
        if(hasObj && ( !formData[item] || !formData[item].length)){
            return {title: formObj[item], 
                    success: false}
        }
    }
    return {
        title: null,
        success: true
    }
}